// ** React Imports
import { useEffect } from 'react';

// ** Next Imports
import { useRouter } from 'next/router';

// ** Hook Imports
import { useAuth } from 'react-oidc-context';

// ** MUI License management
import { LicenseInfo } from '@mui/x-license-pro';

import VigoSpinner from 'src/@vigo/vigo-spinner';

/**
 *  Set Home URL based on User Roles *** FIX THIS ***
 */
export const getHomeRoute = (role: string) => {
  if (role === 'client') return '/acl';
  else return '/dashboards/Home';
};

const Home = () => {
  // license the MUI controls
  LicenseInfo.setLicenseKey(
    '124a4961b050697cc776046d12160373Tz05NTY3MyxFPTE3NTQ1NjMxNTAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
  );

  // ** Hooks
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    sessionStorage.removeItem('app_redirect');
    if (auth.user) {
      const homeRoute = getHomeRoute('admin');
      router.replace(homeRoute);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <VigoSpinner name="homeSpinner" />;
};

export default Home;
